<template>
  <div class="my-8">
    <div
      v-if="!image && !saving && !savedImage"
      class="input-box"
    >
      <label for="img-uploader" :class="fullWidth ? 'fullWidth' : ''">
        <div class="upload-box">
          <v-icon>
            mdi-file-upload
          </v-icon>
          <span class="subtitle-1">
            {{ title || 'Clique aqui para enviar uma imagem.' }}
          </span>

          <span
            v-if="subtitle"
            v-html="subtitle"
            class="subtitle-2"
          />
        </div>
        <input
          id="img-uploader"
          type="file"
          style="display:none"
          @change="handleImage()"
        >
      </label>
    </div>

    <v-card
      v-if="image || savedImage"
      flat
      class="d-flex flex-column justify-center align-center"
    >
      <v-img
        :src="image || savedImage"
        :width="previewWidth"
        :height="previewHeight"
        class="preview-box"
        @error="imageErrorHandler()"
      >
        <v-btn
          small
          class="remove-btn"
          color="error lighten-2"
          :disabled="saving"
          @click="removeImage()"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-img>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    saving: {
      type: Boolean,
      required: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    },
    savedImage: {
      type: String,
      required: false
    },
    previewWidth: {
      type: String,
      required: false,
      default: '50%'
    },
    previewHeight: {
      type: String,
      required: false
    },
    errorImage: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      image: null
    }
  },
  methods: {
    handleImage () {
      var img = document.getElementById('img-uploader').files[0]
      const reader = new FileReader()
      if (img) {
        reader.readAsDataURL(img)
        reader.onload = () => {
          this.image = reader.result
        }
        this.$emit('img-uploaded', img)
      }
    },
    removeImage () {
      this.image = null
      this.$emit('img-removed')
    },
    imageErrorHandler () {
      if (this.errorImage) {
        this.image = this.errorImage
      }
    }
  }
}
</script>

<style scoped>
  .input-box {
    display: flex;
    justify-content: center;
  }

  .upload-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 120px;
    padding: 10px;
  }

  .upload-box, .preview-box {
    border: 1px solid #0000006b;
    border-radius: 4px;
  }

  .remove-btn {
    position: absolute;
    top: 0;
    right: 0;
  }

  .fullWidth {
    width: 100% !important;
  }
</style>
